
    import './styles.scoped.css';
    export default {
  "card-inner": "awsui_card-inner_p8a6i_d5cjx_157",
  "root": "awsui_root_p8a6i_d5cjx_189",
  "header-variant-full-page": "awsui_header-variant-full-page_p8a6i_d5cjx_231",
  "header-refresh": "awsui_header-refresh_p8a6i_d5cjx_231",
  "list": "awsui_list_p8a6i_d5cjx_238",
  "list-grid-1": "awsui_list-grid-1_p8a6i_d5cjx_249",
  "card": "awsui_card_p8a6i_d5cjx_157",
  "list-grid-2": "awsui_list-grid-2_p8a6i_d5cjx_252",
  "list-grid-3": "awsui_list-grid-3_p8a6i_d5cjx_255",
  "list-grid-4": "awsui_list-grid-4_p8a6i_d5cjx_258",
  "list-grid-5": "awsui_list-grid-5_p8a6i_d5cjx_261",
  "list-grid-6": "awsui_list-grid-6_p8a6i_d5cjx_264",
  "list-grid-7": "awsui_list-grid-7_p8a6i_d5cjx_267",
  "list-grid-8": "awsui_list-grid-8_p8a6i_d5cjx_270",
  "list-grid-9": "awsui_list-grid-9_p8a6i_d5cjx_273",
  "list-grid-10": "awsui_list-grid-10_p8a6i_d5cjx_276",
  "list-grid-11": "awsui_list-grid-11_p8a6i_d5cjx_279",
  "list-grid-12": "awsui_list-grid-12_p8a6i_d5cjx_282",
  "list-grid-13": "awsui_list-grid-13_p8a6i_d5cjx_285",
  "list-grid-14": "awsui_list-grid-14_p8a6i_d5cjx_288",
  "list-grid-15": "awsui_list-grid-15_p8a6i_d5cjx_291",
  "list-grid-16": "awsui_list-grid-16_p8a6i_d5cjx_294",
  "list-grid-17": "awsui_list-grid-17_p8a6i_d5cjx_297",
  "list-grid-18": "awsui_list-grid-18_p8a6i_d5cjx_300",
  "list-grid-19": "awsui_list-grid-19_p8a6i_d5cjx_303",
  "list-grid-20": "awsui_list-grid-20_p8a6i_d5cjx_306",
  "selection-control": "awsui_selection-control_p8a6i_d5cjx_310",
  "loading": "awsui_loading_p8a6i_d5cjx_320",
  "empty": "awsui_empty_p8a6i_d5cjx_321",
  "has-header": "awsui_has-header_p8a6i_d5cjx_328",
  "refresh": "awsui_refresh_p8a6i_d5cjx_331",
  "card-header": "awsui_card-header_p8a6i_d5cjx_402",
  "card-header-inner": "awsui_card-header-inner_p8a6i_d5cjx_410",
  "card-selectable": "awsui_card-selectable_p8a6i_d5cjx_414",
  "card-selected": "awsui_card-selected_p8a6i_d5cjx_417",
  "section": "awsui_section_p8a6i_d5cjx_425",
  "section-header": "awsui_section-header_p8a6i_d5cjx_433",
  "section-content": "awsui_section-content_p8a6i_d5cjx_439",
  "footer-pagination": "awsui_footer-pagination_p8a6i_d5cjx_449"
};
  