import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from './hooks/useGet'
import { usePost } from './hooks/usePost'
import { RadAppLayout } from './common/RadAppLayout'
import { RadAttributeEditor } from './common/RadAttributeEditor'
import { RadHeader } from './common/RadHeader'
import { RadSpaceBetween } from './common/RadSpaceBetween'
import { RadBox } from './common/RadBox'
import { RadButton } from './common/RadButton'
import { RadContainer } from './common/RadContainer'
import { RadFormField } from './common/RadFormField'
import { RadSelect } from './common/RadSelect'
import { RadForm } from './common/RadForm'

export function TopicAreaEdit () {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState()
  const { data: tags } = useGet('/api/tag?limit=2000')
  const { data: tagGroups } = useGet('/api/tagGroup?limit=2000')
  const save = usePost('/api/tagGroup', formValues, (resp) => { navigate('/framework') })

  useEffect(() => {
    setFormValues({ tagGroups: tagGroups ?? [] })
  }, [tagGroups])

  if (
    formValues != null &&
    tags != null &&
    tagGroups != null
  ) {
    const tagOptions = tags.map((x) => { return { value: x.id.toString(), label: x.name } })

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
          >
            Edit DASH Framework
          </RadHeader>
        }
        content={
          <form>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={() => navigate('/framework')}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={save}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Policy and Market Forces
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const tagGroups = formValues.tagGroups
                      tagGroups.push({ uuid: uuidv4(), group: 'Policy and Market Forces' })
                      setFormValues({ ...formValues, tagGroups })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      let tagGroups = formValues.tagGroups
                      const tagGroup = tagGroups.filter((x) => x.group === 'Policy and Market Forces')[itemIndex]
                      tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                      setFormValues({ ...formValues, tagGroups })
                    }}
                    items={formValues.tagGroups.filter((x) => x.group === 'Policy and Market Forces')}
                    addButtonText='Add new tag'
                    definition={[
                      {
                        label: 'Tag *',
                        control: item => {
                          const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Policy and Market Forces').map((y) => y.tagId?.toString()).includes(x.value))
                          const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                onChange={({ detail }) => {
                                  const tagGroups = formValues.tagGroups
                                  tag.tagId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, tagGroups })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a tag'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No tags added.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Building a Foundation with Community
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='xxs'>
                    <RadBox variant='h3'>Shared vision</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Building a Foundation with Community', subGroup: 'Shared vision' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Shared vision')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Shared vision')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Shared vision').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                    <hr style={{ marginTop: '14px' }} />
                    <RadBox variant='h3'>Trust</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Building a Foundation with Community', subGroup: 'Trust' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Trust')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Trust')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Trust').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                    <hr style={{ marginTop: '14px' }} />
                    <RadBox variant='h3'>Shared power</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Building a Foundation with Community', subGroup: 'Shared power' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Shared power')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Shared power')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Shared power').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups.map((x) => x)
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Multisector Community Data Ecosystem
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='xxs'>
                    <RadBox variant='h3'>Human & Organizational Capacity</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Multisector Community Data Ecosystem', subGroup: 'Human & Organizational Capacity' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Human & Organizational Capacity')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Human & Organizational Capacity')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Human & Organizational Capacity').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                    <hr style={{ marginTop: '14px' }} />
                    <RadBox variant='h3'>Data Governance</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Multisector Community Data Ecosystem', subGroup: 'Data Governance' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Data Governance')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Data Governance')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Data Governance').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                    <hr style={{ marginTop: '14px' }} />
                    <RadBox variant='h3'>Data Standards</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Multisector Community Data Ecosystem', subGroup: 'Data Standards' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Data Standards')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Data Standards')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Data Standards').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups.map((x) => x)
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                    <hr style={{ marginTop: '14px' }} />
                    <RadBox variant='h3'>Technology</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Multisector Community Data Ecosystem', subGroup: 'Technology' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Technology')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Technology')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Technology').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups.map((x) => x)
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                    <hr style={{ marginTop: '14px' }} />
                    <RadBox variant='h3'>Sustainable Finance</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Multisector Community Data Ecosystem', subGroup: 'Sustainable Finance' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Sustainable Finance')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Sustainable Finance')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Sustainable Finance').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups.map((x) => x)
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                    <hr style={{ marginTop: '14px' }} />
                    <RadBox variant='h3'>Equity Throughout the Data Cycle</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Multisector Community Data Ecosystem', subGroup: 'Equity Throughout the Data Cycle' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Equity Throughout the Data Cycle')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Equity Throughout the Data Cycle')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Multisector Community Data Ecosystem' && x.subGroup === 'Equity Throughout the Data Cycle').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups.map((x) => x)
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Systems Change That Promotes Equity, Health & Well-being
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='xxs'>
                    <RadBox variant='h3'>Narrative</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Systems Change That Promotes Equity, Health & Well-being', subGroup: 'Narrative' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Systems Change That Promotes Equity, Health & Well-being' && x.subGroup === 'Narrative')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Systems Change That Promotes Equity, Health & Well-being' && x.subGroup === 'Narrative')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Systems Change That Promotes Equity, Health & Well-being' && x.subGroup === 'Narrative').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                    <hr style={{ marginTop: '14px' }} />
                    <RadBox variant='h3'>Building Connections & Sharing Power</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Systems Change That Promotes Equity, Health & Well-being', subGroup: 'Building Connections & Sharing Power' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Systems Change That Promotes Equity, Health & Well-being' && x.subGroup === 'Building Connections & Sharing Power')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Systems Change That Promotes Equity, Health & Well-being' && x.subGroup === 'Building Connections & Sharing Power')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Systems Change That Promotes Equity, Health & Well-being' && x.subGroup === 'Building Connections & Sharing Power').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                    <hr style={{ marginTop: '14px' }} />
                    <RadBox variant='h3'>Equitable Policy & Practice</RadBox>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tagGroups = formValues.tagGroups
                        tagGroups.push({ uuid: uuidv4(), group: 'Systems Change That Promotes Equity, Health & Well-being', subGroup: 'Equitable Policy & Practice' })
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        let tagGroups = formValues.tagGroups
                        const tagGroup = tagGroups.filter((x) => x.group === 'Systems Change That Promotes Equity, Health & Well-being' && x.subGroup === 'Equitable Policy & Practice')[itemIndex]
                        tagGroups = tagGroups.filter((x) => x.id !== tagGroup.id || x.uuid !== tagGroup.uuid)
                        setFormValues({ ...formValues, tagGroups })
                      }}
                      items={formValues.tagGroups.filter((x) => x.group === 'Systems Change That Promotes Equity, Health & Well-being' && x.subGroup === 'Equitable Policy & Practice')}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Tag *',
                          control: item => {
                            const filteredOptions = tagOptions.filter((x) => !formValues.tagGroups.filter((x) => x.group === 'Building a Foundation with Community' && x.subGroup === 'Equitable Policy & Practice').map((y) => y.tagId?.toString()).includes(x.value))
                            const tag = formValues.tagGroups.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                            return (
                              <RadFormField field={`tags.${item.id ?? item.uuid}.tagId`}>
                                <RadSelect
                                  filteringType='auto'
                                  selectedOption={tagOptions.find(x => x.value === item.tagId?.toString())}
                                  onChange={({ detail }) => {
                                    const tagGroups = formValues.tagGroups.map((x) => x)
                                    tag.tagId = parseInt(detail.selectedOption.value)
                                    setFormValues({ ...formValues, tagGroups })
                                  }}
                                  options={filteredOptions}
                                  enteredTextLabel={value => value}
                                  selectedAriaLabel='Selected'
                                  placeholder='Choose a tag'
                                  empty='No matches found'
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
