
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_4c84z_11zun_141",
  "variant-secondary": "awsui_variant-secondary_4c84z_11zun_210",
  "variant-primary": "awsui_variant-primary_4c84z_11zun_245",
  "variant-info": "awsui_variant-info_4c84z_11zun_278",
  "variant-value-large": "awsui_variant-value-large_4c84z_11zun_313",
  "variant-top-navigation": "awsui_variant-top-navigation_4c84z_11zun_345",
  "variant-recovery": "awsui_variant-recovery_4c84z_11zun_378",
  "button": "awsui_button_4c84z_11zun_411",
  "color-inverted": "awsui_color-inverted_4c84z_11zun_446",
  "font-size-body-s": "awsui_font-size-body-s_4c84z_11zun_467",
  "font-size-body-m": "awsui_font-size-body-m_4c84z_11zun_474",
  "font-size-heading-xs": "awsui_font-size-heading-xs_4c84z_11zun_480",
  "font-size-heading-s": "awsui_font-size-heading-s_4c84z_11zun_486",
  "font-size-heading-m": "awsui_font-size-heading-m_4c84z_11zun_493",
  "font-size-heading-l": "awsui_font-size-heading-l_4c84z_11zun_500",
  "font-size-heading-xl": "awsui_font-size-heading-xl_4c84z_11zun_507",
  "font-size-display-l": "awsui_font-size-display-l_4c84z_11zun_514",
  "font-size-inherit": "awsui_font-size-inherit_4c84z_11zun_521",
  "icon-wrapper": "awsui_icon-wrapper_4c84z_11zun_529",
  "icon": "awsui_icon_4c84z_11zun_529"
};
  