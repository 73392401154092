import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadAutosuggest } from '../common/RadAutosuggest'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { stateList } from '../common/stateList'

export function ProjectEditContent ({ project, formValues, setFormValues, modal = false }) {
  const navigate = useNavigate()
  const [targetAreaFilteringText, setTargetAreaFilteringText] = useState('')
  const { data: dashFundingProgramOptions } = useGet('/api/option/type?entity=DASH_funding_program')
  const { data: dataTypeOptions } = useGet('/api/option/type?entity=data_type')
  const { data: organizationOptions } = useGet('/api/option/organization')
  const { data: personOptions } = useGet('/api/option/person')
  const { data: targetAreaOptions } = useGet(`/api/option/target-area?search=${targetAreaFilteringText}`)
  const { data: titleOptions } = useGet('/api/option/type?entity=title')
  const { data: typeOptions } = useGet('/api/option/type?entity=project')
  const { data: tags } = useGet('/api/tag')
  const { data: targetPopulations } = useGet('/api/target-population?limit=1000')
  const { data: useCaseOptions } = useGet('/api/option/type?entity=project_use_case')

  const stateListOptions = [...stateList]
  stateListOptions.unshift({ value: null, label: '-' })

  useEffect(() => {
    const defaultFormValues = {
      collaboratives: [],
      contacts: [],
      organizations: [],
      people: [],
      resources: [],
      sectors: [],
      tags: [],
      targetAreas: [],
      targetPopulations: [],
      topics: [],
      useCases: [],
      users: []
    }
    setFormValues(project ?? defaultFormValues)
  }, [project])

  const create = usePost('/api/project', formValues, (resp) => { navigate(`/project?projectId=${resp.id}`) })
  const update = usePut(`/api/project/${project?.id}`, formValues, (resp) => { navigate(`/project/${project?.id}`) })

  async function saveChanges () {
    if (project != null) { update() } else { create() }
  }

  async function cancel () {
    if (project != null) {
      navigate(`/project/${project}`)
    } else {
      navigate('/project')
    }
  }

  if (
    dashFundingProgramOptions != null &&
    dataTypeOptions != null &&
    formValues != null &&
    organizationOptions != null &&
    personOptions != null &&
    tags != null &&
    targetAreaOptions != null &&
    targetPopulations != null &&
    titleOptions != null &&
    typeOptions != null &&
    useCaseOptions != null
  ) {
    return (
      <form onSubmit={e => e.preventDefault()}>
        <RadForm
          actions={
            !modal
              ? (
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
                )
              : null
          }
        >
          <RadSpaceBetween size='l'>

            {/* Details */}
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadFormField label='Name' field='name' required>
                  <RadInput
                    placeholder='Enter name'
                    ariaRequired
                    value={formValues.name}
                    onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                    disabled={formValues.isProtected}
                  />
                </RadFormField>
                <RadFormField label='Description' field='description' required>
                  <RadTextarea
                    placeholder='Enter description'
                    value={formValues.description}
                    onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                  />
                </RadFormField>
                <RadFormField label='Project Highlights' field='deliverables'>
                  <RadTextarea
                    placeholder='Enter project highlights'
                    value={formValues.deliverables}
                    onChange={({ detail }) => setFormValues({ ...formValues, deliverables: detail.value })}
                  />
                </RadFormField>
                <RadFormField label='DASH Funding Program' field='dashFundingProgramId' required>
                  <RadSelect
                    filteringType='auto'
                    selectedOption={dashFundingProgramOptions.find(x => x.value === formValues.dashFundingProgramId?.toString())}
                    onChange={({ detail }) => {
                      setFormValues({ ...formValues, dashFundingProgramId: parseInt(detail.selectedOption.value) })
                    }}
                    options={dashFundingProgramOptions}
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Choose a DASH funding program'
                    empty='No matches found'
                  />
                </RadFormField>
                <RadFormField label='Funding Start Date' field='startDate'>
                  <RadInput
                    type='date'
                    onChange={({ detail }) => setFormValues({ ...formValues, startDate: detail.value })}
                    value={formValues.startDate ?? ''}
                  />
                </RadFormField>
                <RadFormField label='Funding End Date' field='endDate'>
                  <RadInput
                    type='date'
                    onChange={({ detail }) => setFormValues({ ...formValues, endDate: detail.value })}
                    value={formValues.endDate ?? ''}
                  />
                </RadFormField>
              </RadSpaceBetween>
            </RadContainer>

            <Contacts formValues={formValues} setFormValues={setFormValues} organizationOptions={organizationOptions} />
            <Sectors formValues={formValues} setFormValues={setFormValues} />
            <Collaboratives formValues={formValues} setFormValues={setFormValues} />
            <Resources formValues={formValues} setFormValues={setFormValues} />

            {/* Organizations */}
            <RadContainer
              field='organizations'
              header={
                <RadHeader variant='h2'>Organizations *</RadHeader>
              }
            >
              <RadAttributeEditor
                onAddButtonClick={() => {
                  const organizations = formValues.organizations
                  organizations.push({ uuid: uuidv4() })
                  setFormValues({ ...formValues, organizations })
                }}
                onRemoveButtonClick={({
                  detail: { itemIndex }
                }) => {
                  const organizations = [...formValues.organizations]
                  organizations.splice(itemIndex, 1)
                  setFormValues({ ...formValues, organizations })
                }}
                items={formValues.organizations}
                addButtonText='Add new organization'
                definition={[
                  {
                    label: 'Name *',
                    control: item => {
                      const filteredOptions = organizationOptions.filter((x) => !formValues.organizations.map((y) => y.organizationId?.toString()).includes(x.value))
                      const organization = formValues.organizations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                      return (
                        <RadFormField field={`organizations.${item.id ?? item.uuid}.name`}>
                          <RadSelect
                            filteringType='auto'
                            selectedOption={organizationOptions.find(x => x.value === item.organizationId?.toString())}
                            onChange={({ detail }) => {
                              const organizations = formValues.organizations
                              organization.organizationId = parseInt(detail.selectedOption.value)
                              setFormValues({ ...formValues, organizations })
                            }}
                            options={filteredOptions}
                            enteredTextLabel={value => value}
                            selectedAriaLabel='Selected'
                            placeholder='Choose an organization'
                            empty='No matches found'
                          />
                        </RadFormField>
                      )
                    }
                  }
                ]}
                removeButtonText='Remove'
                empty='No organizations added to this project.'
              />
            </RadContainer>

            {/* Tags */}
            <RadContainer
              field='tags'
              header={
                <RadHeader variant='h2'>Tags</RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadAttributeEditor
                  onAddButtonClick={() => {
                    const tags = formValues.tags
                    tags.push({ uuid: uuidv4(), tag: {} })
                    setFormValues({ ...formValues, tags })
                  }}
                  onRemoveButtonClick={({
                    detail: { itemIndex }
                  }) => {
                    const tags = [...formValues.tags]
                    tags.splice(itemIndex, 1)
                    setFormValues({ ...formValues, tags })
                  }}
                  items={formValues.tags}
                  addButtonText='Add new tag'
                  definition={[
                    {
                      label: 'Name',
                      control: item => {
                        const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                        const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.tag.name).includes(x.value))
                        return (
                          <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                            <RadAutosuggest
                              options={filteredOptions}
                              value={item.tag.name ?? ''}
                              enteredTextLabel={value => `Use: "${value}"`}
                              placeholder='Enter tag'
                              onChange={({ detail }) => {
                                const tags = formValues.tags
                                const id = tagOptions.find((x) => x.value === detail.value)?.id
                                item.id = null
                                item.tag.id = id
                                item.tag.name = detail.value
                                setFormValues({ ...formValues, tags })
                              }}
                            />
                          </RadFormField>
                        )
                      }
                    }
                  ]}
                  removeButtonText='Remove'
                  empty='No tags added to this project.'
                />
              </RadSpaceBetween>
            </RadContainer>

            <RadContainer
              field='targetAreas'
              header={
                <RadHeader
                  variant='h2'
                  description='Priority areas are states, counties, cities or zip codes.'
                >
                  Priority Areas *
                </RadHeader>
              }
            >
              <RadAttributeEditor
                onAddButtonClick={() => {
                  const targetAreas = formValues.targetAreas
                  targetAreas.push({ uuid: uuidv4(), targetArea: {} })
                  setFormValues({ ...formValues, targetAreas })
                }}
                onRemoveButtonClick={({
                  detail: { itemIndex }
                }) => {
                  const targetAreas = [...formValues.targetAreas]
                  targetAreas.splice(itemIndex, 1)
                  setFormValues({ ...formValues, targetAreas })
                }}
                items={formValues.targetAreas}
                addButtonText='Add new priority area'
                definition={[
                  {
                    label: 'Name *',
                    control: item => {
                      const targetAreas = formValues.targetAreas
                      const targetArea = formValues.targetAreas.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                      const filteredOptions = (targetAreaOptions ?? []).filter((x) => formValues.targetAreas.find((y) => y.targetArea.name === x.label) == null)
                      return (
                        <RadFormField field={`targetArea.${item.id ?? item.uuid}.targetAreaId`}>
                          <RadSelect
                            selectedOption={item.targetAreaId != null ? { value: item.targetAreaId, label: item.targetArea.name } : null}
                            onChange={({ detail }) => {
                              targetArea.targetAreaId = parseInt(detail.selectedOption.value)
                              targetArea.targetArea.name = detail.selectedOption.label
                              setFormValues({ ...formValues, targetAreas })
                            }}
                            options={filteredOptions}
                            enteredTextLabel={value => value}
                            selectedAriaLabel='Selected'
                            placeholder='Choose a priority area'
                            filteringType='manual'
                            onLoadItems={({ detail }) => {
                              setTargetAreaFilteringText(detail.filteringText)
                            }}
                            empty={targetAreaFilteringText !== '' ? 'No matches found' : null}
                          />
                        </RadFormField>
                      )
                    }
                  }
                ]}
                removeButtonText='Remove'
                empty='No priority areas added to this project.'
              />
            </RadContainer>

            {/* Priority Populations */}
            <RadContainer
              field='targetPopulations'
              header={
                <RadHeader variant='h2'>Priority Populations *</RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadAttributeEditor
                  onAddButtonClick={() => {
                    const targetPopulations = formValues.targetPopulations
                    targetPopulations.push({ uuid: uuidv4(), targetPopulation: {} })
                    setFormValues({ ...formValues, targetPopulations })
                  }}
                  onRemoveButtonClick={({
                    detail: { itemIndex }
                  }) => {
                    const targetPopulations = [...formValues.targetPopulations]
                    targetPopulations.splice(itemIndex, 1)
                    setFormValues({ ...formValues, targetPopulations })
                  }}
                  items={formValues.targetPopulations}
                  addButtonText='Add new priority population'
                  definition={[
                    {
                      label: 'Name',
                      control: item => {
                        const targetPopulationOptions = targetPopulations.map((x) => ({ value: x.id.toString(), label: x.name }))
                        const filteredOptions = (targetPopulationOptions ?? []).filter((x) => !formValues.targetPopulations.map((y) => y.name).includes(x.value))
                        return (
                          <RadFormField field={`targetPopulation.${item.id || item.uuid}.targetPopulationId`}>
                            <RadAutosuggest
                              options={filteredOptions}
                              value={item.targetPopulation.name ?? ''}
                              enteredTextLabel={value => `Use: "${value}"`}
                              placeholder='Enter priority population'
                              onChange={({ detail }) => {
                                const targetPopulations = formValues.targetPopulations
                                const targetPopulation = formValues.targetPopulations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                const selectedOption = targetPopulationOptions.find((x) => x.value === detail.value)
                                targetPopulation.id = null
                                if (selectedOption == null) {
                                  targetPopulation.targetPopulationId = null
                                  targetPopulation.targetPopulation.name = detail.value
                                } else {
                                  targetPopulation.targetPopulationId = parseInt(selectedOption.value)
                                  targetPopulation.targetPopulation.name = selectedOption.label
                                }
                                setFormValues({ ...formValues, targetPopulations })
                              }}
                            />
                          </RadFormField>
                        )
                      }
                    }
                  ]}
                  removeButtonText='Remove'
                  empty='No priority populations added to this project.'
                />
              </RadSpaceBetween>
            </RadContainer>

            {/* Project Types */}
            <RadContainer
              field='useCases'
              header={
                <RadHeader variant='h2'>Project Types</RadHeader>
              }
            >
              <RadAttributeEditor
                onAddButtonClick={() => {
                  const useCases = formValues.useCases
                  useCases.push({ uuid: uuidv4() })
                  setFormValues({ ...formValues, useCases })
                }}
                onRemoveButtonClick={({
                  detail: { itemIndex }
                }) => {
                  const useCases = [...formValues.useCases]
                  useCases.splice(itemIndex, 1)
                  setFormValues({ ...formValues, useCases })
                }}
                items={formValues.useCases}
                addButtonText='Add new project type'
                definition={[
                  {
                    label: 'Project Type *',
                    control: item => {
                      const useCases = formValues.useCases
                      const useCase = formValues.useCases.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                      const filteredOptions = useCaseOptions.filter((x) => !formValues.useCases.map((y) => y.useCaseId?.toString()).includes(x.value))
                      return (
                        <RadFormField field={`useCase.${item.id ?? item.uuid}.useCaseId`}>
                          <RadSelect
                            filteringType='auto'
                            selectedOption={useCaseOptions.find(x => x.value === item.useCaseId?.toString())}
                            onChange={({ detail }) => {
                              useCase.useCaseId = parseInt(detail.selectedOption.value)
                              useCase.name = detail.selectedOption.label
                              useCase.lastName = detail.selectedOption.label.split(' ')[1]
                              setFormValues({ ...formValues, useCases })
                            }}
                            options={filteredOptions}
                            enteredTextLabel={value => value}
                            selectedAriaLabel='Selected'
                            placeholder='Choose a project type'
                            empty='No matches found'
                          />
                        </RadFormField>
                      )
                    }
                  }
                ]}
                removeButtonText='Remove'
                empty='No project types added to this project.'
              />
            </RadContainer>

            <Topics formValues={formValues} setFormValues={setFormValues} />

          </RadSpaceBetween>
        </RadForm>
      </form>
    )
  }
}

function Collaboratives ({ formValues, setFormValues }) {
  const [filteringText, setFilteringText] = useState('')
  const { data: collaborativeOptions } = useGet(`/api/option/collaborative?search=${filteringText}`)

  if (collaborativeOptions != null) {
    return (
      <RadContainer
        field='collaboratives'
        header={
          <RadHeader variant='h2'>Collaboratives</RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const collaboratives = formValues.collaboratives
            collaboratives.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, collaboratives })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const collaboratives = [...formValues.collaboratives]
            collaboratives.splice(itemIndex, 1)
            setFormValues({ ...formValues, collaboratives })
          }}
          items={formValues.collaboratives}
          addButtonText='Add new collaborative'
          definition={[
            {
              label: 'Name *',
              control: item => {
                const filteredOptions = collaborativeOptions.filter((x) => !formValues.collaboratives.map((y) => y.collaborativeId?.toString()).includes(x.value))
                return (
                  <RadFormField field={`collaborative.${item.id ?? item.uuid}.collaborativeId`}>
                    <RadSelect
                      filteringType='auto'
                      selectedOption={collaborativeOptions.find(x => x.value === item.collaborativeId?.toString())}
                      onChange={({ detail }) => {
                        const collaboratives = formValues.collaboratives
                        const collaborative = formValues.collaboratives.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                        collaborative.collaborativeId = parseInt(detail.selectedOption.value)
                        collaborative.name = detail.selectedOption.label
                        setFormValues({ ...formValues, collaboratives })
                      }}
                      onLoadItems={({ detail }) => {
                        setFilteringText(detail.filteringText)
                      }}
                      options={filteredOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a collaborative'
                      empty='No matches found'
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='No collaboratives added to this project.'
        />
      </RadContainer>
    )
  }
}

function Contacts ({ formValues, setFormValues, organizationOptions }) {
  return (
    <RadContainer
      field='contacts'
      header={
        <RadHeader variant='h2'>Contacts *</RadHeader>
      }
    >
      <RadAttributeEditor
        onAddButtonClick={() => {
          const contacts = formValues.contacts
          contacts.push({ uuid: uuidv4() })
          setFormValues({ ...formValues, contacts })
        }}
        onRemoveButtonClick={({
          detail: { itemIndex }
        }) => {
          const contacts = [...formValues.contacts]
          contacts.splice(itemIndex, 1)
          setFormValues({ ...formValues, contacts })
        }}
        items={formValues.contacts}
        addButtonText='Add new contact'
        definition={[
          {
            label: 'Name *',
            control: item => {
              return (
                <RadFormField field={`contact.${item.id ?? item.uuid}.name`}>
                  <RadInput
                    onChange={({ detail }) => {
                      const contacts = formValues.contacts
                      item.name = detail.value
                      setFormValues({ ...formValues, contacts })
                    }}
                    placeholder='Enter name'
                    ariaRequired
                    value={item.name}
                    disabled={formValues.isProtected}
                  />
                </RadFormField>
              )
            }
          },
          {
            label: 'Organization',
            control: item => {
              return (
                <RadFormField field={`contact.${item.id ?? item.uuid}.organizationId`}>
                  <RadSelect
                    filteringType='auto'
                    selectedOption={organizationOptions.find(x => x.value === item.organizationId?.toString())}
                    onChange={({ detail }) => {
                      const contacts = formValues.contacts
                      item.organizationId = parseInt(detail.selectedOption.value)
                      setFormValues({ ...formValues, contacts })
                    }}
                    options={organizationOptions}
                    enteredTextLabel={value => value}
                    selectedAriaLabel='Selected'
                    placeholder='Choose an organization'
                    empty='No matches found'
                  />
                </RadFormField>
              )
            }
          }
        ]}
        removeButtonText='Remove'
        empty='No contacts added to this organization.'
      />
    </RadContainer>
  )
}

function Resources ({ formValues, setFormValues }) {
  const [filteringText, setFilteringText] = useState('')
  const { data: resourceOptions } = useGet(`/api/option/resource?search=${filteringText}`)

  if (resourceOptions != null) {
    return (
      <RadContainer
        field='resources'
        header={
          <RadHeader variant='h2'>Resources</RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const resources = formValues.resources
            resources.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, resources })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const resources = [...formValues.resources]
            resources.splice(itemIndex, 1)
            setFormValues({ ...formValues, resources })
          }}
          items={formValues.resources}
          addButtonText='Add new resource'
          definition={[
            {
              label: 'Title *',
              control: item => {
                const filteredOptions = resourceOptions.filter((x) => !formValues.resources.map((y) => y.resourceId?.toString()).includes(x.value))
                return (
                  <RadFormField field={`resource.${item.id ?? item.uuid}.resourceId`}>
                    <RadSelect
                      filteringType='auto'
                      selectedOption={resourceOptions.find(x => x.value === item.resourceId?.toString())}
                      onChange={({ detail }) => {
                        const resources = formValues.resources
                        const resource = formValues.resources.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                        resource.resourceId = parseInt(detail.selectedOption.value)
                        resource.name = detail.selectedOption.label
                        setFormValues({ ...formValues, resources })
                      }}
                      onLoadItems={({ detail }) => {
                        setFilteringText(detail.filteringText)
                      }}
                      options={filteredOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a resource'
                      empty='No matches found'
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='No resources added to this organization.'
        />
      </RadContainer>
    )
  }
}

function Sectors ({ formValues, setFormValues }) {
  const { data: sectorOptions } = useGet('/api/option/sector')

  if (sectorOptions != null) {
    return (
      <RadContainer
        field='sectors'
        header={
          <RadHeader variant='h2'>Sectors *</RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const sectors = formValues.sectors
            sectors.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, sectors })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const sectors = [...formValues.sectors]
            sectors.splice(itemIndex, 1)
            setFormValues({ ...formValues, sectors })
          }}
          items={formValues.sectors}
          addButtonText='Add new sector'
          definition={[
            {
              label: 'Name *',
              control: item => {
                const filteredOptions = sectorOptions.filter((x) => !formValues.sectors.map((y) => y.sectorId?.toString()).includes(x.value))
                return (
                  <RadFormField field={`sectors.${item.id ?? item.uuid}.sectorId`}>
                    <RadSelect
                      filteringType='auto'
                      selectedOption={sectorOptions.find(x => x.value === item.sectorId?.toString())}
                      onChange={({ detail }) => {
                        const sectors = formValues.sectors
                        const sector = formValues.sectors.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                        sector.sectorId = parseInt(detail.selectedOption.value)
                        sector.name = detail.selectedOption.label
                        setFormValues({ ...formValues, sectors })
                      }}
                      options={filteredOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a sector'
                      empty='No matches found'
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='No sectors added to this project.'
        />
      </RadContainer>
    )
  }
}

function Topics ({ formValues, setFormValues }) {
  const [filteringText, setFilteringText] = useState('')
  const { data: topicOptions } = useGet(`/api/option/topic?search=${filteringText}&required=true`)

  if (topicOptions != null) {
    return (
      <RadContainer
        field='topics'
        header={
          <RadHeader variant='h2'>Include in Framework?</RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const topics = formValues.topics
            topics.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, topics })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const topics = [...formValues.topics]
            topics.splice(itemIndex, 1)
            setFormValues({ ...formValues, topics })
          }}
          items={formValues.topics}
          addButtonText='Add new topic'
          definition={[
            {
              label: 'Name *',
              control: item => {
                const filteredOptions = topicOptions.filter((x) => !formValues.topics.map((y) => y.topicId?.toString()).includes(x.value))
                return (
                  <RadFormField field={`topic.${item.id ?? item.uuid}.topicId`}>
                    <RadSelect
                      filteringType='auto'
                      selectedOption={topicOptions.find(x => x.value === item.topicId?.toString())}
                      onChange={({ detail }) => {
                        const topics = formValues.topics
                        const topic = formValues.topics.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                        topic.topicId = parseInt(detail.selectedOption.value)
                        topic.name = detail.selectedOption.label
                        setFormValues({ ...formValues, topics })
                      }}
                      onLoadItems={({ detail }) => {
                        setFilteringText(detail.filteringText)
                      }}
                      options={filteredOptions.map((x) => { return { value: x.value, label: x.label } })}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a framework'
                      empty='No matches found'
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='Not included in any frameworks.'
        />
      </RadContainer>
    )
  }
}
