import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadTable } from '../common/RadTable'

export function DatasetDetail () {
  const navigate = useNavigate()
  const { datasetId } = useParams()
  const { data: dataset } = useGet(`/api/dataset/${datasetId}`)
  const confirmDelete = useConfirm('Delete dataset?', 'Delete dataset permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/dataset/${datasetId}`, () => { navigate('/dataset') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (dataset != null) {
    return (
      <RadAppLayout
        name={dataset.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={dataset.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={dataset.description}
          >
            {dataset.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Provider
                  </RadBox>
                  <RadLink href={`/organization/${dataset.provider.id}`}>{dataset.provider.name}</RadLink>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Analysis
                  </RadBox>
                  <div>{dataset.analysis ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Sectors
                  </RadBox>
                  <div>{dataset.sectors.length > 0 ? dataset.sectors.map((x) => x.sector.name).join(', ') : '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Tags
                  </RadBox>
                  <div>{dataset.tags.length > 0 ? dataset.tags.map((x) => x.tag.name).join(', ') : '-'}</div>
                </div>
              </RadSpaceBetween>
            </RadContainer>
            <RadTable
              header={
                <RadHeader>
                  Columns
                </RadHeader>
              }
              columnDefinitions={[
                {
                  id: 'name',
                  header: 'Name',
                  cell: e => e.name
                },
                {
                  id: 'dataTypeId',
                  header: 'DataType',
                  cell: e => e.dataType.name
                },
                {
                  id: 'notNullable',
                  header: 'Not Nullable',
                  cell: e => e.notNullable ? 'Yes' : 'No'
                }
              ]}
              items={dataset.columns}
            />
            <RadTable
              header={
                <RadHeader>
                  Contacts
                </RadHeader>
              }
              columnDefinitions={[
                {
                  id: 'name',
                  header: 'Name',
                  cell: e => <RadLink href={`/person/${e.person.id}`}>{e.person.fullName}</RadLink>
                }
              ]}
              items={dataset.contacts}
            />
            <RadTable
              header={
                <RadHeader>
                  Organizations
                </RadHeader>
              }
              columnDefinitions={[
                {
                  id: 'name',
                  header: 'Name',
                  cell: e => <RadLink href={`/organization/${e.organization.id}`}>{e.organization.name}</RadLink>
                }
              ]}
              items={dataset.organizations}
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}
