import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { OrganizationDetailContent } from './OrganizationDetailContent'

export function OrganizationDetail () {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const { data: organization } = useGet(`/api/organization/${organizationId}`)
  const { data: currentUser } = useGet('/api/user/current')
  const confirmDelete = useConfirm('Delete organization?', 'Delete organization permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/organization/${organizationId}`, () => { navigate('/organization') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (organization != null && currentUser != null) {
    const currentUserPermissions = currentUser?.roles?.reduce((acc, x) => acc.concat(x.permissions.map(y => y.name.toLowerCase())), []) ?? []
    const currentUserIsAdmin = currentUser?.roles?.some(x => x.name === 'Admin') ?? false

    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              { text: 'Organizations & Collaboratives', href: '/organization-collaborative' },
              { text: organization.name }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                {(currentUserIsAdmin || currentUserPermissions.includes('organization edit')) && <RadButton onClick={edit}>Edit</RadButton>}
                {(currentUserIsAdmin || currentUserPermissions.includes('organization delete')) && <RadButton onClick={confirmDelete} disabled={organization.isProtected}>Delete</RadButton>}
              </RadSpaceBetween>
            }
            description={organization.description}
          >
            {organization.name}
          </RadHeader>
        }
        content={
          <OrganizationDetailContent organization={organization} />
        }
      />
    )
  }
}
