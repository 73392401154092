
    import './styles.scoped.css';
    export default {
  "toggle": "awsui_toggle_1uo6m_dsy1m_189",
  "drawer-triggers": "awsui_drawer-triggers_1uo6m_dsy1m_195",
  "drawer": "awsui_drawer_1uo6m_dsy1m_195",
  "drawer-mobile": "awsui_drawer-mobile_1uo6m_dsy1m_205",
  "drawer-closed": "awsui_drawer-closed_1uo6m_dsy1m_208",
  "drawer-content": "awsui_drawer-content_1uo6m_dsy1m_215",
  "drawer-content-clickable": "awsui_drawer-content-clickable_1uo6m_dsy1m_227",
  "drawer-resize-content": "awsui_drawer-resize-content_1uo6m_dsy1m_237",
  "drawer-triggers-wrapper": "awsui_drawer-triggers-wrapper_1uo6m_dsy1m_243",
  "drawer-trigger": "awsui_drawer-trigger_1uo6m_dsy1m_195",
  "drawer-trigger-active": "awsui_drawer-trigger-active_1uo6m_dsy1m_262",
  "resize-handle-wrapper": "awsui_resize-handle-wrapper_1uo6m_dsy1m_270",
  "hide": "awsui_hide_1uo6m_dsy1m_280"
};
  