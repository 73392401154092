
    import './styles.scoped.css';
    export default {
  "option": "awsui_option_1p2cx_3m2n8_149",
  "disabled": "awsui_disabled_1p2cx_3m2n8_189",
  "parent": "awsui_parent_1p2cx_3m2n8_192",
  "highlighted": "awsui_highlighted_1p2cx_3m2n8_195",
  "content": "awsui_content_1p2cx_3m2n8_199",
  "label-content": "awsui_label-content_1p2cx_3m2n8_206",
  "label": "awsui_label_1p2cx_3m2n8_206",
  "tag": "awsui_tag_1p2cx_3m2n8_213",
  "label-tag": "awsui_label-tag_1p2cx_3m2n8_214",
  "label-prefix": "awsui_label-prefix_1p2cx_3m2n8_224",
  "tags": "awsui_tags_1p2cx_3m2n8_237",
  "description": "awsui_description_1p2cx_3m2n8_238",
  "selected": "awsui_selected_1p2cx_3m2n8_249",
  "icon": "awsui_icon_1p2cx_3m2n8_263",
  "icon-size-big": "awsui_icon-size-big_1p2cx_3m2n8_270",
  "filtering-match-highlight": "awsui_filtering-match-highlight_1p2cx_3m2n8_274",
  "trigger-variant": "awsui_trigger-variant_1p2cx_3m2n8_289"
};
  