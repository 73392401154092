import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { ProjectDetailContent } from './ProjectDetailContent'

export function ProjectDetail () {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { data: project } = useGet(`/api/project/${projectId}`, true)
  const { data: currentUser } = useGet('/api/user/current')
  const confirmDelete = useConfirm('Delete project?', 'Delete project permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/project/${projectId}`, () => { navigate('/project') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (project != null && currentUser != null) {
    const currentUserPermissions = currentUser?.roles?.reduce((acc, x) => acc.concat(x.permissions.map(y => y.name.toLowerCase())), []) ?? []
    const currentUserIsAdmin = currentUser?.roles?.some(x => x.name === 'Admin') ?? false

    return (
      <RadAppLayout
        name={project.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                {(currentUserIsAdmin || currentUserPermissions.includes('project edit')) && <RadButton onClick={edit}>Edit</RadButton>}
                {(currentUserIsAdmin || currentUserPermissions.includes('project delete')) && <RadButton onClick={confirmDelete} disabled={project.isProtected}>Delete</RadButton>}
              </RadSpaceBetween>
            }
            description={project.description}
          >
            {project.name}
          </RadHeader>
        }
        content={
          <RadContainer
            header={
              <RadHeader variant='h2'>
                Details
              </RadHeader>
                }
          >
            <ProjectDetailContent project={project} />
          </RadContainer>
        }
      />
    )
  }
}
