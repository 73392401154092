
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1fj9k_1ufvx_5",
  "navigation": "awsui_navigation_1fj9k_1ufvx_9",
  "navigation-toggle": "awsui_navigation-toggle_1fj9k_1ufvx_13",
  "navigation-close": "awsui_navigation-close_1fj9k_1ufvx_17",
  "content": "awsui_content_1fj9k_1ufvx_21",
  "notifications": "awsui_notifications_1fj9k_1ufvx_25",
  "breadcrumbs": "awsui_breadcrumbs_1fj9k_1ufvx_29",
  "tools": "awsui_tools_1fj9k_1ufvx_33",
  "tools-close": "awsui_tools-close_1fj9k_1ufvx_37",
  "tools-toggle": "awsui_tools-toggle_1fj9k_1ufvx_41",
  "drawer-closed": "awsui_drawer-closed_1fj9k_1ufvx_45",
  "mobile-bar": "awsui_mobile-bar_1fj9k_1ufvx_49",
  "disable-body-scroll-root": "awsui_disable-body-scroll-root_1fj9k_1ufvx_53",
  "drawers-trigger": "awsui_drawers-trigger_1fj9k_1ufvx_57",
  "active-drawer": "awsui_active-drawer_1fj9k_1ufvx_61",
  "active-drawer-close-button": "awsui_active-drawer-close-button_1fj9k_1ufvx_65",
  "overflow-menu": "awsui_overflow-menu_1fj9k_1ufvx_69",
  "drawers-slider": "awsui_drawers-slider_1fj9k_1ufvx_73",
  "toolbar": "awsui_toolbar_1fj9k_1ufvx_77"
};
  