
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1i0s3_1i4yo_141",
  "header": "awsui_header_1i0s3_1i4yo_176",
  "content": "awsui_content_1i0s3_1i4yo_180",
  "error": "awsui_error_1i0s3_1i4yo_184",
  "footer": "awsui_footer_1i0s3_1i4yo_188",
  "actions-section": "awsui_actions-section_1i0s3_1i4yo_192",
  "secondary-actions": "awsui_secondary-actions_1i0s3_1i4yo_201",
  "actions": "awsui_actions_1i0s3_1i4yo_192"
};
  