import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadForm } from '../common/RadForm'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadTextarea } from '../common/RadTextarea'
import { RadFormField } from '../common/RadFormField'
import { RadButton } from '../common/RadButton'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { stateList } from '../common/stateList'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadAutosuggest } from '../common/RadAutosuggest'

export function DatasetEdit () {
  const navigate = useNavigate()
  const { datasetId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: dataset } = useGet(datasetId ? `/api/dataset/${datasetId}` : null)
  const { data: dataTypeOptions } = useGet('/api/option/type?entity=data_type')
  const { data: organizationOptions } = useGet('/api/option/organization')
  const { data: personOptions } = useGet('/api/option/person')
  const { data: sectorOptions } = useGet('/api/option/sector')
  const { data: titleOptions } = useGet('/api/option/type?entity=title')
  const { data: typeOptions } = useGet('/api/option/type?entity=organization')
  const { data: tags } = useGet('/api/tag')

  const stateListOptions = [...stateList]
  stateListOptions.unshift({ value: null, label: '-' })

  useEffect(() => {
    const defaultFormValues = { contacts: [], columns: [], organizations: [], people: [], sectors: [], users: [], tags: [] }
    setFormValues(dataset ?? defaultFormValues)
  }, [dataset])

  const create = usePost('/api/dataset', formValues, (resp) => { navigate(`/dataset/${resp.id}`) })
  const update = usePut(`/api/dataset/${datasetId}`, formValues, (resp) => { navigate(`/dataset/${datasetId}`) })

  async function saveChanges () {
    if (datasetId != null) { update() } else { create() }
  }

  async function cancel () {
    if (datasetId != null) {
      navigate(`/dataset/${datasetId}`)
    } else {
      navigate('/dataset')
    }
  }

  if (
    formValues != null &&
    typeOptions != null &&
    personOptions != null &&
    titleOptions != null &&
    sectorOptions != null &&
    tags != null &&
    organizationOptions != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Dataset' : 'New Dataset'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>

                {/* Details */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Provider *' field='providerId'>
                      <RadSelect
                        filteringType='auto'
                        selectedOption={organizationOptions.find(x => x.value === formValues.providerId?.toString())}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, providerId: parseInt(detail.selectedOption.value) })
                        }}
                        options={organizationOptions}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a provider'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Analysis' field='analysis'>
                      <RadTextarea
                        placeholder='Enter analysis'
                        value={formValues.analysis}
                        onChange={({ detail }) => setFormValues({ ...formValues, analysis: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>

                {/* Collaboratives */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Collaboratives</RadHeader>
                  }
                >
                  Not implemented yet
                </RadContainer>

                {/* Columns */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Columns</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const columns = formValues.columns
                      columns.push({ uuid: uuidv4(), notNullable: false })
                      setFormValues({ ...formValues, columns })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const people = [...formValues.people]
                      people.splice(itemIndex, 1)
                      setFormValues({ ...formValues, people })
                    }}
                    items={formValues.columns}
                    addButtonText='Add new column'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const columns = formValues.columns
                          const column = formValues.columns.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`columns.${item.id ?? item.uuid}.name`}>
                              <RadInput
                                placeholder='Enter name'
                                ariaRequired
                                value={column.name}
                                onChange={({ detail }) => {
                                  column.name = detail.value
                                  setFormValues({ ...formValues, columns })
                                }}
                                disabled={formValues.isProtected}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Data Type *',
                        control: item => {
                          const columns = formValues.columns
                          const column = formValues.columns.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`columns.${item.id ?? item.uuid}.dataTypeId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={dataTypeOptions.find(x => x.value === item.dataTypeId?.toString())}
                                onChange={({ detail }) => {
                                  column.dataTypeId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, columns })
                                }}
                                options={dataTypeOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a title'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Not Nullable *',
                        control: item => {
                          const columns = formValues.columns
                          const column = formValues.columns.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`columns.${item.id ?? item.uuid}.notNullable`}>
                              <RadCheckbox
                                checked={column.notNullable}
                                onChange={({ detail }) => {
                                  column.notNullable = detail.checked
                                  setFormValues({ ...formValues, columns })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No columns added to this dataset.'
                  />
                </RadContainer>

                {/* Contacts */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Contacts</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const contacts = formValues.contacts
                      contacts.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, contacts })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const contacts = [...formValues.contacts]
                      contacts.splice(itemIndex, 1)
                      setFormValues({ ...formValues, contacts })
                    }}
                    items={formValues.contacts}
                    addButtonText='Add new contact'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = personOptions.filter((x) => !formValues.contacts.map((y) => y.personId?.toString()).includes(x.value))
                          const person = formValues.contacts.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`contacts.${item.id ?? item.uuid}.personId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={personOptions.find(x => x.value === item.personId?.toString())}
                                onChange={({ detail }) => {
                                  const contacts = formValues.contacts
                                  person.personId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, contacts })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a contact'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No contacts added to this organization.'
                  />
                </RadContainer>

                {/* Organizations */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Organizations</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const organizations = formValues.organizations
                      organizations.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, organizations })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const organizations = [...formValues.organizations]
                      organizations.splice(itemIndex, 1)
                      setFormValues({ ...formValues, organizations })
                    }}
                    items={formValues.organizations}
                    addButtonText='Add new organization'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = organizationOptions.filter((x) => !formValues.organizations.map((y) => y.organizationId?.toString()).includes(x.value))
                          const organization = formValues.organizations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`organizations.${item.id ?? item.uuid}.organizationId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={organizationOptions.find(x => x.value === item.organizationId?.toString())}
                                onChange={({ detail }) => {
                                  const organizations = formValues.organizations
                                  organization.organizationId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, organizations })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose an organization'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No contacts added to this organization.'
                  />
                </RadContainer>

                {/* Projects */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Projects</RadHeader>
                  }
                >
                  Not implemented yet
                </RadContainer>

                {/* Sectors */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Sectors</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const sectors = formValues.sectors
                      sectors.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, sectors })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const sectors = [...formValues.sectors]
                      sectors.splice(itemIndex, 1)
                      setFormValues({ ...formValues, sectors })
                    }}
                    items={formValues.sectors}
                    addButtonText='Add new sector'
                    definition={[
                      {
                        label: 'Sector *',
                        control: item => {
                          const sectors = formValues.sectors
                          const sector = formValues.sectors.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                          const filteredOptions = sectorOptions.filter((x) => !formValues.sectors.map((y) => y.sectorId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`sectors.${item.id ?? item.uuid}.id`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={sectorOptions.find(x => x.value === item.sectorId?.toString())}
                                onChange={({ detail }) => {
                                  sector.sectorId = parseInt(detail.selectedOption.value)
                                  sector.name = detail.selectedOption.label
                                  sector.lastName = detail.selectedOption.label.split(' ')[1]
                                  setFormValues({ ...formValues, sectors })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a sector'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No sectors added to this organization.'
                  />
                </RadContainer>

                {/* Tags */}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Tags</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tags = formValues.tags
                        tags.push({ uuid: uuidv4(), tag: {} })
                        setFormValues({ ...formValues, tags })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const tags = [...formValues.tags]
                        tags.splice(itemIndex, 1)
                        setFormValues({ ...formValues, tags })
                      }}
                      items={formValues.tags}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Name',
                          control: item => {
                            const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                            const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.tag.name).includes(x.value))
                            return (
                              <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                                <RadAutosuggest
                                  options={filteredOptions}
                                  value={item.tag.name ?? ''}
                                  enteredTextLabel={value => `Use: "${value}"`}
                                  placeholder='Enter tag'
                                  onChange={({ detail }) => {
                                    const tags = formValues.tags
                                    const tag = formValues.tags.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                    const id = tagOptions.find((x) => x.value === detail.value)?.id
                                    tag.id = null
                                    tag.tag.id = id
                                    tag.tag.name = detail.value
                                    setFormValues({ ...formValues, tags })
                                  }}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added to this resource.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
