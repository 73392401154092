
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_l0dv0_1ix1z_141",
  "header": "awsui_header_l0dv0_1ix1z_178",
  "with-toolbar": "awsui_with-toolbar_l0dv0_1ix1z_190",
  "header-link": "awsui_header-link_l0dv0_1ix1z_194",
  "header-link--has-logo": "awsui_header-link--has-logo_l0dv0_1ix1z_204",
  "header-link-text": "awsui_header-link-text_l0dv0_1ix1z_204",
  "header-logo": "awsui_header-logo_l0dv0_1ix1z_209",
  "header-logo--stretched": "awsui_header-logo--stretched_l0dv0_1ix1z_215",
  "list-container": "awsui_list-container_l0dv0_1ix1z_220",
  "list": "awsui_list_l0dv0_1ix1z_220",
  "list-variant-root": "awsui_list-variant-root_l0dv0_1ix1z_235",
  "list-variant-root--last": "awsui_list-variant-root--last_l0dv0_1ix1z_242",
  "list-variant-expandable-link-group": "awsui_list-variant-expandable-link-group_l0dv0_1ix1z_246",
  "list-item": "awsui_list-item_l0dv0_1ix1z_250",
  "section": "awsui_section_l0dv0_1ix1z_258",
  "expandable-link-group": "awsui_expandable-link-group_l0dv0_1ix1z_259",
  "section--no-ident": "awsui_section--no-ident_l0dv0_1ix1z_262",
  "expandable-link-group--no-ident": "awsui_expandable-link-group--no-ident_l0dv0_1ix1z_263",
  "refresh": "awsui_refresh_l0dv0_1ix1z_271",
  "list-variant-section-group": "awsui_list-variant-section-group_l0dv0_1ix1z_279",
  "section-group": "awsui_section-group_l0dv0_1ix1z_286",
  "section-group-title": "awsui_section-group-title_l0dv0_1ix1z_297",
  "link": "awsui_link_l0dv0_1ix1z_301",
  "link-active": "awsui_link-active_l0dv0_1ix1z_310",
  "info": "awsui_info_l0dv0_1ix1z_347",
  "external-icon": "awsui_external-icon_l0dv0_1ix1z_351",
  "divider": "awsui_divider_l0dv0_1ix1z_355",
  "divider-default": "awsui_divider-default_l0dv0_1ix1z_360",
  "divider-header": "awsui_divider-header_l0dv0_1ix1z_366"
};
  