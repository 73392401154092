import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGet } from './hooks/useGet'
import { RadAppLayout } from './common/RadAppLayout'
import { RadBox } from './common/RadBox'
import { RadContainer } from './common/RadContainer'
import { RadExpandableSection } from './common/RadExpandableSection'
import { RadGrid } from './common/RadGrid'
import { RadHeader } from './common/RadHeader'
import { RadInput2 } from './common/RadInput2'
import { RadLink } from './common/RadLink'
import { RadButton } from './common/RadButton'
import { RadSpaceBetween } from './common/RadSpaceBetween'
import './Dashboard.scss'

export function Dashboard () {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState()
  const { data: topSearches } = useGet('/api/search/top')


  return (
    <RadAppLayout
      breadcrumbs=''
      content={
        <div className='container'>
          <div className='search'>
            <span>Search the DASH Knowledge Base</span>
            <RadInput2
              initialValue={searchText}
              placeholder='Type what you are looking for'
              type='search'
              onEnter={({ detail }) => {
                setSearchText(detail.value)
                if (detail.value === '') return
                navigate(`/search?search=${detail.value.replaceAll(' ', '+')}`)
              }}
            />
            {topSearches?.length > 0 &&
              <div className='suggestions'>
                <span>Try searching for</span>
                <div className='suggestions-list'>
                  {topSearches?.map((search, index) => (

                    <button
                      key={index}
                      onClick={() => {
                        navigate(`/search?search=${search.text.replaceAll(' ', '+')}`)
                      }}
                    >
                      "{search.text}"
                    </button>
                  ))}
                </div>
              </div>}
          </div>

          <div className='browse'>
            <span>Looking to Browse? Click below to explore a specific category in the DKB</span>
            <div>
              <button
                onClick={() => { navigate('/organization-collaborative') }}
              >
                <img
                  src='organizations_collaboratives.svg'
                  alt='Organizations & Collaboratives'
                />
                <div>
                  Organizations &
                  <br />
                  Collaboratives
                </div>
              </button>
              <button onClick={() => { navigate('/project') }}>
                <img
                  style={{ paddingLeft: '8px', width: '38px', height: '38px' }}
                  src='projects.svg'
                  alt='Projects'
                />
                <div>Projects</div>
              </button>
              <button onClick={() => { navigate('/resource') }}>
                <img
                  src='resources.svg'
                  alt='Resources'
                  style={{ paddingBottom: '0' }}
                />
                <div>Resources</div>
              </button>
            </div>
          </div>
          <div className='or'>OR</div>
          <div className='framework'>
            <button onClick={() => { navigate('/framework') }}>
              <div>See examples of the DASH<br />Framework in action</div>
              <img src='/framework.png' alt='Framework' />
            </button>
          </div>
        </div>
      }
    />
  )
}

export function Dashboard2 () {
  const [notifications] = useState([])
  const [expandedSection, setExpandedSection] = useState()
  const { data: tagGroups } = useGet('/api/tagGroup')

  if (
    tagGroups != null
  ) {
    return (
      <RadAppLayout
        breadcrumbs=''
        notifications={notifications}
        contentHeader={
          <RadHeader
            variant='h1'
          >
            DKB: DASH Knowledge Base
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              variant='stacked'
              header={
                <RadHeader
                  variant='h1'
                >
                  A resource for your multi-sector data-sharing efforts to improve community health
                </RadHeader>
              }
            >
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 4 } },
                  { colspan: { default: 12, xs: 4 } },
                  { colspan: { default: 12, xs: 4 } }
                ]}
              >
                <RadBox textAlign='center'>
                  <RadHeader
                    description='Search across the entire site by using general keywords.'
                  >
                    <RadBox variant='h3' textAlign='center'>
                      Global Search
                    </RadBox>
                  </RadHeader>
                  <br />
                  <RadButton href='/search'>Global Search</RadButton>
                </RadBox>
                <RadBox textAlign='center'>
                  <RadHeader
                    description='Start by narrowing your search to one of five categories.'
                  >
                    <RadBox variant='h3' textAlign='center'>
                      Category Search
                    </RadBox>
                  </RadHeader>
                  <br />
                  <RadSpaceBetween size='xs'>
                    {/* <RadButton href='/collaborative'>Collaboratives</RadButton>
                    <RadButton href='/organization'>Organizations</RadButton> */}
                    {/* <RadButton href='/person'>People</RadButton> */}
                    <RadButton href='/organization-collaborative'>Organizations & Collaboratives</RadButton>
                    <RadButton href='/project'>Projects</RadButton>
                    <RadButton href='/resource'>Resources</RadButton>
                  </RadSpaceBetween>
                </RadBox>
                <RadBox textAlign='center'>
                  <RadHeader
                    description='Search within the DASH Framework.'
                  >
                    <RadBox variant='h3' textAlign='center'>
                      DASH Framework
                    </RadBox>
                  </RadHeader>
                  <br />
                  <RadButton href='/framework'>DASH Framework</RadButton>
                </RadBox>
              </RadGrid>
            </RadContainer>
            <RadContainer
              variant='stacked'
              header={
                <RadHeader
                  variant='h1'
                >
                  About the DKB
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadExpandableSection
                  onChange={({ detail: { expanded } }) => setExpandedSection(expanded ? 1 : null)}
                  expanded={expandedSection === 1}
                  headerText='Who is Data Across Sectors for Health (DASH)?'
                >
                  <p>
                    Data Across Sectors for Health (DASH) is a national initiative that helps communities
                    and organizations in the social services, public health, and healthcare sectors share
                    data so that every person can reach their full potential for health.
                  </p>
                  <p>
                    Since 2015, DASH has provided funding, resources, and technical assistance to more
                    than 200 community projects nationwide. DASH is co-led by the Illinois Public Health
                    Institute and the Michigan Public Health Institute.
                  </p>
                  Visit <RadLink href='https://dashconnect.org' target='_blank'>dashconnect.org</RadLink> to learn more
                </RadExpandableSection>
                <RadExpandableSection
                  onChange={({ detail: { expanded } }) => setExpandedSection(expanded ? 2 : null)}
                  expanded={expandedSection === 2}
                  headerText='What is the DASH Knowledge Base (DKB)?'
                >
                  <p>
                    The DASH Knowledge Base (DKB) is a publicly available online knowledge management
                    platform that supports the field of multisector data sharing.
                  </p>
                  <p>
                    It provides a repository of organizations, collaboratives, projects, and
                    resources. The purpose of the DKB is to help reveal new opportunities for collaboration
                    and more impactful solutions for communities pursuing health equity.
                  </p>
                </RadExpandableSection>
                <RadExpandableSection
                  onChange={({ detail: { expanded } }) => setExpandedSection(expanded ? 3 : null)}
                  expanded={expandedSection === 3}
                  headerText='What is the DASH Framework?'
                >
                  <p>
                    Since 2015, Data Across Sectors for Health (DASH) has been funding community collaborations that aim to share data, or use multi-sector data, to improve community health.
                  </p>
                  <p>
                    Along the way, DASH has collected insights and learnings from their network of partners and grant recipients. One result of this work is the DASH Framework,
                    which maps out all potential considerations in multi-sector data-sharing projects. Think of it as a map for data sharing that identifies all major landmarks.
                  </p>
                  <p>
                    It is a living, conceptual guide that is updated periodically based on insights from the field including subject matter experts, DASH grantees, and partners.
                  </p>
                  <p>
                    <RadLink href='https://www.dashconnect.org/dash-overview/#dashframework'>Learn more about the DASH Framework</RadLink>
                  </p>
                </RadExpandableSection>
              </RadSpaceBetween>
            </RadContainer>

            <RadContainer>
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, s: 3 } },
                  { colspan: { default: 12, s: 9 } }
                ]}
              >
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <img src='https://www.networkforphl.org/wp-content/uploads/2019/09/logo.png' alt='Network for Public Health Law' style={{ width: '180px', paddingRight: '20px' }} />
                </div>
                <RadBox fontSize='body-s'>
                  Special thanks to <RadLink fontSize='inherit' href='https://www.networkforphl.org/' target='_blank' rel='noreferrer'> The Network for Public Health Law</RadLink>&nbsp;
                  for the curation and contribution of legal bibliography resources in part through support from
                  the Robert Wood Johnson Foundation.
                  <br />
                  <RadLink fontSize='inherit' href='https://docs.google.com/document/d/1Cqmm-1fzysqWY-ayCCZ9sntgursK_Z-OvPISN9A9m7U/edit' target='_blank' rel='noreferrer'>Learn more about the legal bibliography resources and the methodology used.</RadLink>
                </RadBox>
              </RadGrid>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
