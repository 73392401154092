import React, { useEffect, useState } from 'react'
import { useGet } from './hooks/useGet'
import { RadAppLayout } from './common/RadAppLayout'
import { RadBox } from './common/RadBox'
import { RadContainer } from './common/RadContainer'
import { RadGrid } from './common/RadGrid'
import { RadHeader } from './common/RadHeader'
import { RadLink } from './common/RadLink'
import { RadSpaceBetween } from './common/RadSpaceBetween'
import { EntityModal } from './shared/EntityModal'

export function TopicAreaSearch () {
  // const navigate = useNavigate()
  // const [notifications, setNotifications] = useState([])
  const [selectedTopicId, setSelectedTopicId] = useState()
  const [selectedEntity, setSelectedEntity] = useState()
  const { data: tagGroups } = useGet('/api/tagGroup')
  const { data: topicOptions } = useGet('/api/option/topic')
  const { data: entities } = useGet(selectedTopicId != null ? `/api/topic/${selectedTopicId}` : null)
  // const { data: userInfo } = useGet('/api/user/current')

  // function edit () {
  //   navigate('edit')
  // }

  // function getLink (group, subGroup) {
  //   const tagIds = Array.from(new Set(
  //     tagGroups
  //       .filter((x) =>
  //         (group == null || x.group === group) && (subGroup == null || x.subGroup === subGroup)
  //       )
  //       .map(x => x.tagId)))
  //   if (tagIds.length === 0) {
  //     setNotifications([{ type: 'info', content: `${subGroup == null ? group : subGroup} has no tags configured.`, dismissible: true, onDismiss: () => setNotifications([]) }])
  //   } else {
  //     setNotifications([])
  //     navigate(`/search?tagIds=${tagIds.join('-')}`)
  //   }
  // }

  useEffect(() => {
    if (selectedEntity != null) {
      setSelectedTopicId(null)
    }
  }, [selectedEntity])

  function handleClick (topicName, parentName) {
    const topic = topicOptions.find(
      x => x.label === topicName + (parentName != null ? ' - ' + parentName : '')
    )
    if (topic != null) {
      setSelectedTopicId(parseInt(topic.value))
    }
  }

  if (
    tagGroups != null &&
    topicOptions != null
  ) {
    return (
      <RadAppLayout
        breadcrumbs=''
        // notifications={notifications}
        contentHeader={
          <RadHeader
            variant='h1'
            // actions={
            //   <RadSpaceBetween direction='horizontal' size='xs'>
            //     {userInfo != null && userInfo.roles.find(x => x.name === 'Admin') != null && <RadButton onClick={edit}>Edit</RadButton>}
            //   </RadSpaceBetween>
            // }
          >
            DASH Framework
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              variant='stacked'
              disableContentPaddings
              header={
                <RadHeader
                  variant='h2'
                  description='Click on a topic in the image to find relevant content.'
                >
                  DASH Framework: Building Equitable Data Ecosystems for Fostering Community Health
                </RadHeader>
              }
            >
              <div style={{ position: 'relative' }}>
                <img src='/framework.png' alt='Framework' style={{ width: '100%', borderRadius: '16px' }} />
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Policy and Market Forces')}
                  style={{ position: 'absolute', top: '2.5%', left: '39%', bottom: '86%', right: '38.9%' }}
                >
                  Policy and Market Forces
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Building a Foundation with Community')}
                  style={{ position: 'absolute', top: '21%', left: '5.6%', bottom: '65%', right: '75.1%' }}
                >
                  Building a Foundation with Community
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Building a Foundation with Community', 'Shared Vision')}
                  style={{ position: 'absolute', top: '35%', left: '5.6%', bottom: '53%', right: '75.1%' }}
                >
                  Building a Foundation with Community - Shared vision
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Building a Foundation with Community', 'Trust')}
                  style={{ position: 'absolute', top: '47%', left: '5.6%', bottom: '41.5%', right: '75.1%' }}
                >
                  Building a Foundation with Community - Trust
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Building a Foundation with Community', 'Shared Power')}
                  style={{ position: 'absolute', top: '58.5%', left: '5.6%', bottom: '26.7%', right: '75.1%' }}
                >
                  Building a Foundation with Community - Shared power
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Multisector Community Data Ecosystem')}
                  style={{ position: 'absolute', top: '18.5%', left: '30.5%', bottom: '70%', right: '30.5%' }}
                >
                  Multisector Community Data Ecosystem
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Multisector Community Data Ecosystem', 'Human & Organizational Capacity')}
                  style={{ position: 'absolute', top: '30%', left: '46.6%', bottom: '53.2%', right: '43.7%' }}
                >
                  Multisector Community Data Ecosystem - Human & Organizational Capacity
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Multisector Community Data Ecosystem', 'Data Governance')}
                  style={{ position: 'absolute', top: '46.6%', left: '54.5%', bottom: '37%', right: '38%' }}
                >
                  Multisector Community Data Ecosystem - Data Governance
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Multisector Community Data Ecosystem', 'Data Standards')}
                  style={{ position: 'absolute', top: '63%', left: '48.2%', bottom: '19.4%', right: '42.2%' }}
                >
                  Multisector Community Data Ecosystem - Data Standards
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Multisector Community Data Ecosystem', 'Technology')}
                  style={{ position: 'absolute', top: '58.2%', left: '38%', bottom: '23.4%', right: '52.5%' }}
                >
                  Multisector Community Data Ecosystem - Data Standards
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Multisector Community Data Ecosystem', 'Sustainable Finance')}
                  style={{ position: 'absolute', top: '38.2%', left: '37.2%', bottom: '43.2%', right: '53.4%' }}
                >
                  Multisector Community Data Ecosystem - Sustainable Finance
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Multisector Community Data Ecosystem', 'Equity Throughout the Data Cycle')}
                  style={{ position: 'absolute', top: '48.2%', left: '46.4%', bottom: '37.6%', right: '46.6%' }}
                >
                  Multisector Community Data Ecosystem - Equity Throughout the Data Cycle
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Systems Change')}
                  style={{ position: 'absolute', top: '21.2%', left: '75%', bottom: '63.8%', right: '4.8%' }}
                >
                  Systems Change That Promotes Equity, Health & Well - being
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Systems Change', 'Narrative')}
                  style={{ position: 'absolute', top: '36.2%', left: '75%', bottom: '52.5%', right: '4.8%' }}
                >
                  Systems Change That Promotes Equity, Health & Well - being - Narrative
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Systems Change', 'Building Connections & Sharing Power')}
                  style={{ position: 'absolute', top: '47.2%', left: '75%', bottom: '41.2%', right: '4.8%' }}
                >
                  Systems Change That Promotes Equity, Health & Well - being - Building Connections & Sharing Power
                </button>
                <button
                  className='transparent-text'
                  onClick={() => handleClick('Systems Change', 'Equitable Policy & Practice')}
                  style={{ position: 'absolute', top: '58.8%', left: '75%', bottom: '26.5%', right: '4.8%' }}
                >
                  Systems Change That Promotes Equity, Health & Well - being - Equitable Policy & Practice
                </button>
              </div>
            </RadContainer>
            <RadContainer
              variant='stacked'
              header={
                <RadHeader
                  variant='h1'
                >
                  What is the DASH Framework?
                </RadHeader>
              }
            >
              <p>
                Since 2015, Data Across Sectors for Health (DASH) has been funding community collaborations that aim to share data, or use multi-sector data, to improve community health.
              </p>
              <p>
                Along the way, DASH has collected insights and learnings from their network of partners and grant recipients. One result of this work is the DASH Framework,
                which maps out all potential considerations in multi-sector data-sharing projects. Think of it as a map for data sharing that identifies all major landmarks.
              </p>
              <p>
                It is a living, conceptual guide that is updated periodically based on insights from the field including subject matter experts, DASH grantees, and partners.
              </p>
              <p>
                <RadLink href='https://www.dashconnect.org/dash-overview/#dashframework'>Learn more about the DASH Framework</RadLink>
              </p>
            </RadContainer>

            <RadContainer
              variant='stacked'
              header={
                <RadHeader
                  variant='h1'
                >
                  DASH Framework Topic Areas
                </RadHeader>
              }
            >
              <RadGrid
                gridDefinition={[
                  { colspan: 6 },
                  { colspan: 6 }
                ]}
              >
                <RadSpaceBetween size='l'>
                  <RadHeader
                    variant='h3'
                    description='Community Foundations are the cornerstone for equitable and successful data sharing. It is important that collaborations begin not with technology, but with human relationships, sharing power with the community, deepening trust and partnerships, and developing a shared vision.'
                  >
                    Community Foundations
                  </RadHeader>
                  <RadHeader
                    variant='h3'
                    description='Community data ecosystems are made up of the what, the who, and the how that enables sharing data and collaboration within a community. The who includes community members who are represented in and by the data (and the forms in which they organize), in addition to organizations operating in sectors such as healthcare, public health, community based social services and others.'
                  >
                    Multisector Community Data Ecosystem
                  </RadHeader>
                  <RadHeader
                    variant='h3'
                    description='Participating organizations and staff have attributes needed to be effective, community-engaged data-sharing partners.'
                  >
                    Human & Organizational Capacity
                  </RadHeader>
                  <RadHeader
                    variant='h3'
                    description='The process of establishing and ensuring the freedoms, constraints, and incentives that determine how parties agree to conduct the collection, use, protection, sharing of data, tools, methods, and knowledge amongst themselves and with others.'
                  >
                    Data Governance
                  </RadHeader>
                  <RadHeader
                    variant='h3'
                    description='Consistent data formatting, security and transport protocols within a community network.'
                  >
                    Data Standards
                  </RadHeader>
                </RadSpaceBetween>
                <RadSpaceBetween size='l'>
                  <RadHeader
                    variant='h3'
                    description='Hardware and software that supports functions related to the secure sending, receiving, and processing of data.'
                  >
                    Technology
                  </RadHeader>
                  <RadHeader
                    variant='h3'
                    description='Funding structures that enable longevity in sharing data that is responsive to the needs and values of community partners.'
                  >
                    Sustainable Finance
                  </RadHeader>
                  <RadHeader
                    variant='h3'
                    description='Data practices promote equity from the identification of the problem, through collecting, analyzing, and sharing the data, to the dissemination of the implications on policy and practice.'
                  >
                    Equity throughout the Data Cycle
                  </RadHeader>
                  <RadHeader
                    variant='h3'
                    description='State and Federal policy impacts community foundations and data ecosystems; the work in communities can impact policy by serving as bright spots, generating learning and effective models, or through direct advocacy and narrative change. Many important actors in communities and data ecosystems are also operating in markets which exert powerful forces on competition and collaboration.'
                  >
                    Policy and Market Forces
                  </RadHeader>
                  <RadHeader
                    variant='h3'
                    description='Changing systems requires addressing root causes of inequity resulting from complex interacting parts. Data may support this by: supporting new policies and practices, impacting power and relationships and changing mental models or narratives.'
                  >
                    Systems Change
                  </RadHeader>
                </RadSpaceBetween>
              </RadGrid>
            </RadContainer>

            <EntityModal
              title={selectedTopicId != null ? topicOptions.find(x => parseInt(x.value) === selectedTopicId).label : null}
              description={selectedTopicId != null ? <RadBox padding={{ bottom: 'l' }}>{topicOptions.find(x => parseInt(x.value) === selectedTopicId).description}</RadBox> : null}
              entities={entities}
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              closeModal={() => {
                if (selectedEntity != null) setSelectedEntity(null)
                if (selectedTopicId != null) setSelectedTopicId(null)
              }}
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}
