
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_dm8gx_1cz2b_149",
  "has-items": "awsui_has-items_dm8gx_1cz2b_183",
  "no-padding": "awsui_no-padding_dm8gx_1cz2b_183",
  "dismiss-button": "awsui_dismiss-button_dm8gx_1cz2b_187",
  "token": "awsui_token_dm8gx_1cz2b_229",
  "token-box": "awsui_token-box_dm8gx_1cz2b_236",
  "token-box-error": "awsui_token-box-error_dm8gx_1cz2b_255",
  "token-box-warning": "awsui_token-box-warning_dm8gx_1cz2b_266",
  "token-box-readonly": "awsui_token-box-readonly_dm8gx_1cz2b_277",
  "token-box-disabled": "awsui_token-box-disabled_dm8gx_1cz2b_291"
};
  