
    import './styles.scoped.css';
    export default {
  "selectable-item": "awsui_selectable-item_15o6u_benbj_141",
  "pad-bottom": "awsui_pad-bottom_15o6u_benbj_163",
  "has-background": "awsui_has-background_15o6u_benbj_170",
  "highlighted": "awsui_highlighted_15o6u_benbj_173",
  "selected": "awsui_selected_15o6u_benbj_173",
  "disabled": "awsui_disabled_15o6u_benbj_191",
  "next-item-selected": "awsui_next-item-selected_15o6u_benbj_201",
  "is-keyboard": "awsui_is-keyboard_15o6u_benbj_213",
  "parent": "awsui_parent_15o6u_benbj_217",
  "interactiveGroups": "awsui_interactiveGroups_15o6u_benbj_221",
  "child": "awsui_child_15o6u_benbj_237",
  "virtual": "awsui_virtual_15o6u_benbj_252",
  "measure-strut": "awsui_measure-strut_15o6u_benbj_263",
  "measure-strut-first": "awsui_measure-strut-first_15o6u_benbj_272",
  "screenreader-content": "awsui_screenreader-content_15o6u_benbj_276",
  "option-content": "awsui_option-content_15o6u_benbj_282"
};
  