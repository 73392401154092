import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadBadgeGroup } from '../common/RadBadgeGroup'

export function CollaborativeDetail () {
  const navigate = useNavigate()
  const { collaborativeId } = useParams()
  const { data: collaborative } = useGet(`/api/collaborative/${collaborativeId}`)
  const { data: currentUser } = useGet('/api/user/current')
  const confirmDelete = useConfirm('Delete collaborative?', 'Delete collaborative permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/collaborative/${collaborativeId}`, () => { navigate('/collaborative') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (collaborative != null && currentUser != null) {
    const currentUserPermissions = currentUser?.roles?.reduce((acc, x) => acc.concat(x.permissions.map(y => y.name.toLowerCase())), []) ?? []
    const currentUserIsAdmin = currentUser?.roles?.some(x => x.name === 'Admin') ?? false

    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              { text: 'Organizations & Collaboratives', href: '/organization-collaborative' },
              { text: collaborative.name }
            ]}
            ariaLabel='Breadcrumbs'
          />
      }
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                {(currentUserIsAdmin || currentUserPermissions.includes('collaborative edit')) && <RadButton onClick={edit}>Edit</RadButton>}
                {(currentUserIsAdmin || currentUserPermissions.includes('collaborative delete')) && <RadButton onClick={confirmDelete} disabled={collaborative.isProtected}>Delete</RadButton>}
              </RadSpaceBetween>
            }
            description={collaborative.description}
          >
            {collaborative.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadColumnLayout columns={4} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Goal
                    </RadBox>
                    <div>{collaborative.goal ?? '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Contacts
                    </RadBox>
                    <div>{collaborative.contacts.length > 0
                      ? collaborative.contacts.map((x) => <div key={x.id}>{x.name}</div>)
                      : '-'}
                    </div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Organizations
                    </RadBox>
                    <div>{collaborative.organizations.length > 0 ? collaborative.organizations.map((x) => <div key={x.id}><RadLink href={`/organization/${x.organization.id}`}>{x.organization.name}</RadLink></div>) : '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Projects
                    </RadBox>
                    <div>{collaborative.projects.length > 0 ? collaborative.projects.map((x) => <div key={x.id}><RadLink href={`/project/${x.project.id}`}>{x.project.name}</RadLink></div>) : '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Sectors
                    </RadBox>
                    <div>
                      <RadBadgeGroup
                        parentKey={collaborativeId}
                        items={collaborative.sectors}
                        mapFunc={x => ({ key: x.id, name: x.sector.name })}
                      />
                    </div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Tags
                    </RadBox>
                    <div>
                      <RadBadgeGroup
                        parentKey={collaborativeId}
                        items={collaborative.tags}
                        mapFunc={x => ({ key: x.id, name: x.tag.name })}
                      />
                    </div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Priority Areas
                    </RadBox>
                    <div>{collaborative.targetAreas.length > 0 ? collaborative.targetAreas.map((x) => <div key={x.id}>{x.targetArea.name}</div>) : '-'}</div>
                  </div>
                </RadColumnLayout>
              </RadSpaceBetween>
            </RadContainer>
            {/* <RadTable
              header={
                <RadHeader>
                  Contacts
                </RadHeader>
              }
              columnDefinitions={[
                {
                  id: 'name',
                  header: 'Name',
                  cell: e => <RadLink href={`/person/${e.person.id}`}>{e.person.fullName}</RadLink>
                }
              ]}
              items={collaborative.contacts}
            />
            <RadTable
              header={
                <RadHeader>
                  Organizations
                </RadHeader>
              }
              columnDefinitions={[
                {
                  id: 'name',
                  header: 'Name',
                  cell: e => <RadLink href={`/organization/${e.organization.id}`}>{e.organization.name}</RadLink>
                }
              ]}
              items={collaborative.organizations}
            /> */}
          </RadSpaceBetween>
        }
      />
    )
  }
}
