import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
// import { RadTable } from '../common/RadTable'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'

export function DatasetList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const { data: datasets, count } = useGet(`/api/dataset?search=${searchText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)

  if (datasets != null) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/dataset/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Datasets
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <RadLink fontSize='heading-l' href={`/dataset/${item.id}`}>{item.name}</RadLink>,
              sections: [
                {
                  id: 'provider',
                  header: 'Provider',
                  content: e => <RadLink href={`/organization/${e.provider.id}`}>{e.provider.name}</RadLink>
                },
                {
                  id: 'description',
                  content: item => item.description
                },
                // {
                //   id: 'description',
                //   header: 'Description',
                //   content: item => item.description ?? '-'
                // },
                {
                  id: 'analysis',
                  header: 'Analysis',
                  content: item => item.analysis ?? '-'
                }

                // {
                //   id: 'sectors',
                //   header: 'Sectors',
                //   content: item => item.sectors.length > 0
                //     ? item.sectors.map((x) => <div key={x.id}><RadLink href={`/sector/${x.id}`}>{x.name}</RadLink></div>)
                //     : '-'
                // },
                // {
                //   id: 'contacts',
                //   header: 'Contacts',
                //   content: item => item.contacts.length > 0
                //     ? item.contacts.map((x) => <div key={x.id}><RadLink href={`/person/${x.person.id}`}>{x.person.fullName}</RadLink></div>)
                //     : '-'
                // }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={datasets}
            variant='full-page'
            filter={
              <RadTextFilter
                filteringPlaceholder='Search within datasets'
                filteringAriaLabel='Search datasets'
                filteringText={filteringText}
                onChange={({ detail }) => setFilteringText(detail.filteringText)}
                onDelayedChange={({ detail }) => {
                  setSearchText(detail.filteringText)
                  setCurrentPageIndex(1)
                  searchParams.set('page', 1)
                  if (detail.filteringText != null) {
                    searchParams.set('search', detail.filteringText)
                  } else {
                    searchParams.delete('search')
                  }
                  setSearchParams(searchParams)
                }}
              />
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
          />
        }
      />
    )
  }
}
