
    import './styles.scoped.css';
    export default {
  "background": "awsui_background_hyvsj_tesys_145",
  "scrolling-background": "awsui_scrolling-background_hyvsj_tesys_148",
  "breadcrumbs": "awsui_breadcrumbs_hyvsj_tesys_159",
  "drawers-container": "awsui_drawers-container_hyvsj_tesys_172",
  "has-open-drawer": "awsui_has-open-drawer_hyvsj_tesys_183",
  "disable-body-scroll": "awsui_disable-body-scroll_hyvsj_tesys_204",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_hyvsj_tesys_209",
  "has-multiple-triggers": "awsui_has-multiple-triggers_hyvsj_tesys_250",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_hyvsj_tesys_260",
  "drawers-trigger-content": "awsui_drawers-trigger-content_hyvsj_tesys_265",
  "drawers-trigger-overflow": "awsui_drawers-trigger-overflow_hyvsj_tesys_279",
  "drawers-trigger": "awsui_drawers-trigger_hyvsj_tesys_265",
  "drawer": "awsui_drawer_hyvsj_tesys_172",
  "drawer-content-container": "awsui_drawer-content-container_hyvsj_tesys_310",
  "drawer-close-button": "awsui_drawer-close-button_hyvsj_tesys_319",
  "drawer-content": "awsui_drawer-content_hyvsj_tesys_310",
  "drawer-content-hidden": "awsui_drawer-content-hidden_hyvsj_tesys_327",
  "drawer-slider": "awsui_drawer-slider_hyvsj_tesys_330",
  "is-drawer-open": "awsui_is-drawer-open_hyvsj_tesys_337",
  "content": "awsui_content_hyvsj_tesys_363",
  "layout": "awsui_layout_hyvsj_tesys_388",
  "has-max-content-width": "awsui_has-max-content-width_hyvsj_tesys_460",
  "content-type-dashboard": "awsui_content-type-dashboard_hyvsj_tesys_475",
  "content-type-table": "awsui_content-type-table_hyvsj_tesys_489",
  "content-type-cards": "awsui_content-type-cards_hyvsj_tesys_489",
  "is-overlap-disabled": "awsui_is-overlap-disabled_hyvsj_tesys_495",
  "is-hide-mobile-toolbar": "awsui_is-hide-mobile-toolbar_hyvsj_tesys_498",
  "has-content-gap-left": "awsui_has-content-gap-left_hyvsj_tesys_512",
  "has-content-gap-right": "awsui_has-content-gap-right_hyvsj_tesys_515",
  "has-breadcrumbs": "awsui_has-breadcrumbs_hyvsj_tesys_525",
  "content-first-child-header": "awsui_content-first-child-header_hyvsj_tesys_528",
  "content-first-child-notifications": "awsui_content-first-child-notifications_hyvsj_tesys_528",
  "has-header": "awsui_has-header_hyvsj_tesys_528",
  "content-first-child-main": "awsui_content-first-child-main_hyvsj_tesys_543",
  "disable-content-paddings": "awsui_disable-content-paddings_hyvsj_tesys_543",
  "has-left-toggles-gutter": "awsui_has-left-toggles-gutter_hyvsj_tesys_563",
  "has-right-toggles-gutter": "awsui_has-right-toggles-gutter_hyvsj_tesys_566",
  "has-split-panel": "awsui_has-split-panel_hyvsj_tesys_586",
  "split-panel-position-bottom": "awsui_split-panel-position-bottom_hyvsj_tesys_586",
  "block-body-scroll": "awsui_block-body-scroll_hyvsj_tesys_594",
  "unfocusable": "awsui_unfocusable_hyvsj_tesys_599",
  "container": "awsui_container_hyvsj_tesys_609",
  "is-navigation-open": "awsui_is-navigation-open_hyvsj_tesys_635",
  "is-tools-open": "awsui_is-tools-open_hyvsj_tesys_638",
  "is-split-panel-open": "awsui_is-split-panel-open_hyvsj_tesys_638",
  "split-panel-position-side": "awsui_split-panel-position-side_hyvsj_tesys_638",
  "has-active-drawer": "awsui_has-active-drawer_hyvsj_tesys_638",
  "mobile-toolbar": "awsui_mobile-toolbar_hyvsj_tesys_647",
  "remove-high-contrast-header": "awsui_remove-high-contrast-header_hyvsj_tesys_664",
  "mobile-toolbar-nav": "awsui_mobile-toolbar-nav_hyvsj_tesys_668",
  "mobile-toolbar-breadcrumbs": "awsui_mobile-toolbar-breadcrumbs_hyvsj_tesys_672",
  "mobile-toolbar-tools": "awsui_mobile-toolbar-tools_hyvsj_tesys_676",
  "navigation-container": "awsui_navigation-container_hyvsj_tesys_685",
  "show-navigation": "awsui_show-navigation_hyvsj_tesys_723",
  "animating": "awsui_animating_hyvsj_tesys_746",
  "showButtons": "awsui_showButtons_hyvsj_tesys_1",
  "navigation": "awsui_navigation_hyvsj_tesys_685",
  "openNavigation": "awsui_openNavigation_hyvsj_tesys_1",
  "animated-content": "awsui_animated-content_hyvsj_tesys_808",
  "hide-navigation": "awsui_hide-navigation_hyvsj_tesys_817",
  "notifications": "awsui_notifications_hyvsj_tesys_827",
  "has-notification-content": "awsui_has-notification-content_hyvsj_tesys_832",
  "sticky-notifications": "awsui_sticky-notifications_hyvsj_tesys_836",
  "high-contrast": "awsui_high-contrast_hyvsj_tesys_841",
  "split-panel-bottom": "awsui_split-panel-bottom_hyvsj_tesys_859",
  "position-bottom": "awsui_position-bottom_hyvsj_tesys_906",
  "openSplitPanelBottom": "awsui_openSplitPanelBottom_hyvsj_tesys_1",
  "split-panel-side": "awsui_split-panel-side_hyvsj_tesys_935",
  "position-side": "awsui_position-side_hyvsj_tesys_948",
  "tools-container": "awsui_tools-container_hyvsj_tesys_970",
  "tools": "awsui_tools_hyvsj_tesys_970",
  "openTools": "awsui_openTools_hyvsj_tesys_1",
  "has-tools-form-persistence": "awsui_has-tools-form-persistence_hyvsj_tesys_1059",
  "hide-tools": "awsui_hide-tools_hyvsj_tesys_1069",
  "show-tools": "awsui_show-tools_hyvsj_tesys_1081",
  "has-tools-form": "awsui_has-tools-form_hyvsj_tesys_1059",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_hyvsj_tesys_1164",
  "trigger": "awsui_trigger_hyvsj_tesys_1164",
  "selected": "awsui_selected_hyvsj_tesys_1242",
  "badge": "awsui_badge_hyvsj_tesys_1262",
  "trigger-wrapper": "awsui_trigger-wrapper_hyvsj_tesys_1266",
  "dot": "awsui_dot_hyvsj_tesys_1277"
};
  